/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation();

        $('.is-accordion-submenu-parent > a').each(function(){
          var submenu = $(this).next('ul');
          $(this).clone().prependTo(submenu).wrap('<li></li>');
        });
		
		
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
	


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
		
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
	  

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function cleanUpText() {
  if ( jQuery('.gform_wrapper').length === 0 ) {
    jQuery('p').each(function () {
      jQuery(this).html(jQuery(this).html().replace(/&nbsp;/gi, " "));
    });
    jQuery('li').each(function () {
      jQuery(this).html(jQuery(this).html().replace(/&nbsp;/gi, " "));
    });
    jQuery('h1').each(function () {
      jQuery(this).html(jQuery(this).html().replace(/&nbsp;/gi, " "));
    });
    jQuery('h2').each(function () {
      jQuery(this).html(jQuery(this).html().replace(/&nbsp;/gi, " "));
    });
    jQuery('h3').each(function () {
      jQuery(this).html(jQuery(this).html().replace(/&nbsp;/gi, " "));
    });
  }

}

function menuCleanUp() {
  var menu = document.location.pathname;
  if (menu.indexOf("careers") >= 0 ){
    jQuery( '#menu-utility-menu-2').hide();
  }
}

function searchFocus() {

  jQuery(document).on("open.zf.reveal", function(ev, $el) {
    jQuery('#searchBox .search-form input[type=search]').focus();
  });
  jQuery(document).on("closed.zf.reveal", function(ev, $el) {
    jQuery('#searchBox .search-form input[type=search]').blur();
  });

}

jQuery(document).ready(function(){
  menuCleanUp();
 
});

jQuery(window).on('load', function(){
  cleanUpText();
  searchFocus();
  jQuery('#navsection_beforejavascript').css('display', 'inline');
  
});

// Phase 2
window.objectFitPolyfill();

// Fix navigation targeting classes
jQuery('#menu-main-menu-2').find('li.current-menu-item').parents('li').addClass('current-menu-ancestor current-page-ancestor');

 
(function($) {
    $('.facetwp-pager').on('click', 'a', function() {
        $('html, body').animate({
            scrollTop: $('#facets').offset().top
        }, 500);
    });
})(jQuery);

(function($) {
    $(document)
      .on('facetwp-loaded', reapplyFoundation)
      .on('facetwp-refresh', reapplyFoundation);

    function reapplyFoundation() {
        $('.recipe-grid, .recipe-grid-item').foundation();
    }
})(jQuery);
